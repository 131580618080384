import * as React from 'react';
import Head from 'next/head';
import { getConfig } from 'Shared/utils/getConfig';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { fromLonLat } from 'ol/proj';
import { MapZoomButton } from 'Molecules/MapZoomButton';
import { Helper3D } from 'Molecules/Helper3D';
import { Image3DView } from 'Molecules/Image3DView';
import { useProject, useUser, useMap } from 'Client/utils/hooks';
import { initXyz } from 'Client/services/geolytix/v4';
import { loadScript } from 'Client/utils/loadScript';
import { CustomLayerHighlight } from 'Client/components/molecules/CustomLayerHighlight';
import { getContributionUserId } from 'Client/services/contributions';
import {
  CONTRIBUTION_SESSION_ITEM,
  getLocalItem,
} from 'Client/utils/localstorage';
import { fetchMapPage } from 'Client/services/map';
import { ListPagesParams } from 'Server/services/pages/types';
import { Xyz, XyzCombined, XyzV4 } from 'Shared/types/map';
import { onPointerMoveV4 } from 'Client/pages/map/utils/onPointerMove';
import { onClickV4 } from 'Client/pages/map/utils/onClick';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { HubHeader } from '..';
import {
  MapSize,
  Image3DWrapper,
  Helper3DWrapper,
} from './NavigationalMap.style';

type NavigationalMapContent = {
  title?: string;
  slug?: string;
  pageId?: string;
};

type NavigationalMapFilter = {
  contributionsIds?: { contributionId: string; pageId: string }[];
};

type NavigationalMapProps = {
  content: NavigationalMapContent;
  view?: {
    lat: number;
    lng: number;
    display3d?: boolean;
  };
  header?: boolean;
  hideZoomButtons?: boolean;
  centered?: { lat: number; lng: number };
  index?: string | number;
  filter?: NavigationalMapFilter;
  isMapChart?: boolean;
};

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const GEOLYTIX_URL =
  publicRuntimeConfig?.geolytix?.urlV4 || serverRuntimeConfig?.geolytix?.urlV4;

const getMapPage = async (
  content: NavigationalMapContent,
  projectId: string,
  apiToken: string,
  fetchConsentsCountGqlEndpoint: boolean
) => {
  const params: ListPagesParams = {};

  if (content?.slug) {
    params.slug = content.slug;
  }

  if (content?.pageId) {
    params.id = content.pageId;
  }

  return await fetchMapPage(
    params,
    projectId,
    apiToken,
    fetchConsentsCountGqlEndpoint
  );
};

export const NavigationalMapV4: React.FC<NavigationalMapProps> = ({
  content,
  header = true,
  view,
  hideZoomButtons = false,
  centered,
  index = 'nav-map',
  filter,
  isMapChart,
}) => {
  const {
    dispatch,
    state: { xyz, highlightedCustomLayer, mode, image3d },
  } = useMap();
  const project = useProject();
  const { apiToken } = useUtils();
  const { user } = useUser();
  const { t } = useTranslation();
  const router = useRouter();

  React.useEffect(() => {
    console.info('Loading XYZ v4');

    const load = async () => {
      const { mapp } = window;
      if (!mapp) return;

      const fetchConsentsCountGqlEndpoint =
        project?.features?.fetchConsentsCountGqlEndpoint || false;
      const projectId = project?._id || '';

      const { open } = window.XMLHttpRequest.prototype;
      const mapPage = await getMapPage(
        content,
        projectId,
        apiToken,
        fetchConsentsCountGqlEndpoint
      );

      function openReplacement(...args) {
        if (args[1].startsWith(GEOLYTIX_URL)) {
          const separator = args[1].includes('?') ? '&' : '?';
          let params = '';

          if (!args[1].includes('lang=')) {
            params += `&lang=${router.locale}`;
          }
          if (!args[1].includes('project=')) {
            params += `&project=${project._id}`;
          }
          if (!args[1].includes('pageId=')) {
            params += `&pageId=${mapPage?._id}`;
          }
          args[1] = args[1] + `${separator}${params}`;
        }
        return open.apply(this, args);
      }
      window.XMLHttpRequest.prototype.open = openReplacement;

      const existingMap =
        isMapChart &&
        document.querySelectorAll(`#OL-${index} > div`).length === 1;

      console.log('existingMap: ', existingMap);

      if (existingMap) return;

      const OL = document.getElementById(`OL-${index}`);

      const xyzV4 = (await initXyz({
        mapp,
        target: OL,
        project: project._id,
        pageId: mapPage?._id,
        hooks: false,
        lang: router.locale,
      })) as XyzV4;

      // Abstraction Layer
      const xyz = {
        layers: {
          list: {
            Contributions: {
              remove: xyzV4.mapview.layers.Contributions.hide,
              ...xyzV4.mapview.layers.Contributions,
            },
            'Postal district': xyzV4.mapview.layers['Postal district'],
            '3d View': xyzV4.mapview.layers['3d View'],
            Custom: xyzV4.mapview.layers.Custom,
            'Mapbox Base': xyzV4.mapview.layers['Mapbox Base'],
            'Custom 4258': xyzV4.mapview.layers['Custom 4258'],
            Satellite: xyzV4.mapview.layers.Satellite,
          },
        },
        locale: xyzV4.mapview.locale,
        map: xyzV4.mapview.Map,
        mapview: {
          flyToBounds: xyzV4.mapview.fitView,
          interaction: {
            initHighlight: xyzV4.mapview.interactions.highlight,
            ...xyzV4.mapview.interactions,
          },
          layer: {
            styleFunction: xyzV4.layer.Style,
          },
          node: {
            style: xyzV4.mapview.Map.getTargetElement().style,
          },
          ...xyzV4.mapview,
        },
        utils: xyzV4.utils,
        version: xyzV4.version,
      } as unknown as XyzCombined;

      if (content.pageId) xyz.layers.list['Contributions'].remove();

      xyz.layers.list['Postal district'].hide();

      if (!project.features.planningApps) xyz.layers.list['Satellite'].hide();

      if (!view?.display3d) xyz.layers.list['3d View'].hide();

      dispatch({
        type: 'INITIALIZE',
        payload: xyz as unknown as Xyz,
      });

      dispatch({
        type: 'INITIALIZE_LAYERS',
        payload: {
          dispatch,
          page: { pageId: mapPage._id, ...mapPage },
          project,
          uniqueId: getContributionUserId({
            user,
            storedUserId: getLocalItem(CONTRIBUTION_SESSION_ITEM),
          }),
          lang: router.locale,
        },
      });

      // Close info panel
      dispatch({
        type: 'TOGGLE_INFO_PANEL',
      });

      return { mapPage, xyz };
    };

    Promise.all([
      loadScript({
        url: 'https://cdn.jsdelivr.net/npm/ol@v7.2.2/dist/ol.js',
        id: 'ol-external',
      }),
      loadScript({ url: `${GEOLYTIX_URL}/js/lib/mapp.js`, id: 'mapp.js' }),
      loadScript({ url: `${GEOLYTIX_URL}/js/lib/ui.js`, id: 'ui.js' }),
    ])
      .then(load)
      .then((data) => {
        const pageId = data.mapPage?._id;

        // Adding the filters
        if (filter && filter.contributionsIds) {
          const { contributionsIds } = filter;

          const hasNestedFilters = router?.query?.nestedFilters?.length > 0;

          const filteredIds = contributionsIds
            .filter((contribution) => {
              return contribution.pageId === pageId;
            })
            .map((contribution) => contribution.contributionId);

          const contributionIdStage =
            filteredIds.length >= 300 && !hasNestedFilters
              ? {}
              : {
                  contribution_id: {
                    in: filteredIds,
                  },
                };

          const filters = [
            {
              project: {
                eq: project._id,
              },
              page_id: {
                eq: pageId,
              },
              ...contributionIdStage,
            },
          ];

          const layerName = 'Contributions';

          if (data.xyz.mapview.layers?.[layerName]) {
            data.xyz.mapview.layers[layerName].filter.current = filters.length
              ? JSON.stringify(filters)
              : null;

            data.xyz.mapview.layers[layerName].reload();
          }
        }

        // Adding the onClick listener
        onClickV4({
          xyz: data.xyz,
          dispatch,
          page: data.mapPage,
          project,
          isNavigationalMap: true,
        });

        // Adding the onPointerMove listener
        onPointerMoveV4({
          xyz: data.xyz,
          pageId: pageId,
          projectId: project._id,
          dispatch,
          isMapChart,
        });
      })
      .finally(() => {
        console.info('Map v4 enabled!', GEOLYTIX_URL);
      });
  }, [content?.slug, filter]);

  React.useEffect(() => {
    if (centered && xyz) {
      const coords = fromLonLat([centered.lng, centered.lat]);
      xyz.map.getView().animate({ center: coords });
    }
  }, [centered, xyz]);

  const display3d = view?.display3d && mode === '3d';
  const showZoomButtons = !hideZoomButtons;

  const renderCustomLayerHighlight =
    highlightedCustomLayer &&
    highlightedCustomLayer.position?.x &&
    highlightedCustomLayer.position?.y &&
    highlightedCustomLayer?.hoverablePopup &&
    !isMapChart;

  return (
    <>
      <Head>
        {GEOLYTIX_URL && (
          <>
            <link rel="stylesheet" href={`${GEOLYTIX_URL}/css/mapp.css`} />
            <link rel="stylesheet" href={`${GEOLYTIX_URL}/css/ui.css`} />
          </>
        )}
      </Head>
      {header && <HubHeader title={content.title || t('Navigational Map')} />}
      <MapSize id="Map" data-testid="navigational-map">
        <MapSize id={`OL-${index}`} />
        {showZoomButtons && <MapZoomButton view={view} />}
      </MapSize>

      {display3d && !image3d && (
        <Helper3DWrapper>
          <Helper3D />
        </Helper3DWrapper>
      )}

      {display3d && image3d && (
        <Image3DWrapper>
          <Image3DView />
        </Image3DWrapper>
      )}

      {/*
      {renderContributionHighlight && <ContributionHighlightNavMap />}
      // TODO: Not working yet - Refer to the CUS-2754 PRs to understand what was changed
      */}
      {renderCustomLayerHighlight && <CustomLayerHighlight isNavigationalMap />}
    </>
  );
};
