import { Answers } from 'Client/pages/proposals';
import {
  ProposalQuestionsContent,
  RatingOption,
} from 'Client/pages/proposals/types';
import { PageFeatures } from 'Shared/types/page';
import { ContributionType, PopulatedAnswer } from 'Shared/types/contribution';

enum PROPOSAL_ACTION_TYPES {
  SET_CURRENT_STEP = 'SET_CURRENT_STEP',
  SET_TOTAL_STEPS = 'SET_TOTAL_STEPS',
  SET_CONTRIBUTION_ID = 'SET_CONTRIBUTION_ID',
  SET_CONTRIBUTION_TYPE = 'SET_CONTRIBUTION_TYPE',
  SET_MODAL_OPEN = 'SET_MODAL_OPEN',
  SET_ANSWERS = 'SET_ANSWERS',
  SET_ANSWERS_POPULATED = 'SET_ANSWERS_POPULATED',
  SET_VOICE_ANSWERS = 'SET_VOICE_ANSWERS',
  SET_SIGNUP_EMAIL = 'SET_SIGNUP_EMAIL',
  SET_HEADER = 'SET_HEADER',
  SET_FOOTER = 'SET_FOOTER',
  SET_CUSTOM_SMILIE_OPTION = 'SET_CUSTOM_SMILIE_OPTION',
  SET_GAMING_BANNER_IS_OPEN = 'SET_GAMING_BANNER_IS_OPEN',
  SET_CUSTOM_TITLE = 'SET_CUSTOM_TITLE',
  SET_CONTENT = 'SET_CONTENT',
}

export type ProposalContextState = {
  proposal: {
    currentStep: number;
    totalSteps: number;
    contributionId: string;
    contributionType: ContributionType | 'comment' | 'agreement';
    answers: Answers;
    answersPopulated: Array<PopulatedAnswer>;
    voiceAnswers: Record<string, string> | ({ transcribeOnly } & never);
    transcribeOnly: boolean;
    signupEmail: string;
    showFooter: boolean;
    showHeader: boolean;
    customQuestionOptions: {
      smilie: {
        id: string;
        options: RatingOption[];
      }[];
    };
    isGamingBannerOpen: boolean;
    customTitle: Record<string, string>;
    featureFlags: PageFeatures;
    content: ProposalQuestionsContent;
  };
};

export type ProposalAction =
  | {
      type: PROPOSAL_ACTION_TYPES.SET_TOTAL_STEPS;
      totalSteps: number;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_CURRENT_STEP;
      currentStep: number;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_ID;
      contributionId: string;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_TYPE;
      contributionType: 'comment' | 'agreement' | ContributionType;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_ANSWERS;
      answers: Answers;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_ANSWERS_POPULATED;
      answersPopulated: Array<PopulatedAnswer>;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_VOICE_ANSWERS;
      voiceAnswers: Record<string, string> | ({ transcribeOnly } & never);
      transcribeOnly?: boolean;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_SIGNUP_EMAIL;
      signupEmail: string;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_HEADER;
      showHeader: boolean;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_FOOTER;
      showFooter: boolean;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_CUSTOM_SMILIE_OPTION;
      smilie: {
        id: string;
        options: RatingOption[];
      }[];
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN;
      isOpen: boolean;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_CUSTOM_TITLE;
      customTitle: Record<string, string>;
    }
  | {
      type: PROPOSAL_ACTION_TYPES.SET_CONTENT;
      content: ProposalQuestionsContent;
    }
  // This type won't ever happen but it prevents TS from prohibiting a default case
  | { type: 'Never' };

const proposalReducer = (
  state: ProposalContextState,
  action: ProposalAction
): ProposalContextState => {
  switch (action.type) {
    case PROPOSAL_ACTION_TYPES.SET_CURRENT_STEP:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          currentStep: action.currentStep,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_TOTAL_STEPS:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          totalSteps: action.totalSteps,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_ID:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          contributionId: action.contributionId,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_TYPE:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          contributionType: action.contributionType,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_ANSWERS:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          answers: action.answers,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_ANSWERS_POPULATED:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          answersPopulated: action.answersPopulated,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_VOICE_ANSWERS:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          voiceAnswers: action.voiceAnswers,
          transcribeOnly:
            action.transcribeOnly ?? state.proposal.transcribeOnly,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_SIGNUP_EMAIL:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          signupEmail: action.signupEmail,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_HEADER:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          showHeader: action.showHeader,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_FOOTER:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          showFooter: action.showFooter,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_CUSTOM_SMILIE_OPTION:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          customQuestionOptions: {
            smilie: action.smilie,
          },
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          isGamingBannerOpen: action.isOpen,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_CUSTOM_TITLE:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          customTitle: action.customTitle,
        },
      };
    case PROPOSAL_ACTION_TYPES.SET_CONTENT:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          content: action.content,
        },
      };
    default:
      throw new Error(
        `${action.type} is not a valid proposal reducer action type`
      );
  }
};

export { proposalReducer, PROPOSAL_ACTION_TYPES };
