import * as React from 'react';
import { RingLoader } from 'Atoms/LoadingButton';
import { Wrapper } from './LoadRing.styles';
import { LoadRingProps } from './types';

export const LoadRing: React.FC<LoadRingProps> = ({
  loadingMessage,
  ...props
}: LoadRingProps) => {
  return (
    <Wrapper data-testid="LoadRing-Wrapper" {...props}>
      <RingLoader {...props} />
      {loadingMessage && <p>{loadingMessage}</p>}
    </Wrapper>
  );
};
