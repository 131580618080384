import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/react';
import { fetchDemographicsByUserByProject } from 'Client/services/demographics';
import {
  getContributionUserId,
  getContributionStatus,
  saveAgreement,
  markAgreementDeletedBothDBs,
} from 'Client/services/contributions';
import { checkOnUserLanguage, getBlockedData } from 'Client/services/user';
import {
  useProject,
  useUser,
  useAnalytics,
  MixpanelEventTypes,
  useProposalContext,
  useMap,
} from 'Client/utils/hooks';
import {
  Contribution,
  ContributionGaudi,
  ContributionType,
  DeletedContributionReason,
} from 'Shared/types/contribution';
import { Demographics } from 'Shared/types/demographics';
import { PROPOSAL_ACTION_TYPES } from 'Client/context/proposalReducer';
import { Button } from 'Molecules';
import {
  CONTRIBUTION_SESSION_ITEM,
  getLocalItem,
} from 'Client/utils/localstorage';
import { useUtils } from 'Client/utils/hooks/useUtils';
import { AgreeButtonProps } from './types';

export const AgreeButton: React.FC<AgreeButtonProps> = ({
  userAgreementId,
  children,
  commentId,
  commentSentiment,
  className,
  updateDate,
  isMapComment,
  contributionProposalSlug,
  pageId,
}) => {
  const { i18n } = useTranslation();
  const [agreeButtonInteractive, setAgreeButtonInteractive] =
    React.useState(true);
  const { user } = useUser();
  const { apiToken } = useUtils();
  const project = useProject();
  const router = useRouter();
  const { trackEvent } = useAnalytics();
  const [, dispatch] = useProposalContext();
  const { dispatch: mapDispatch } = useMap();

  React.useEffect(() => {
    setAgreeButtonInteractive(true);
  }, [userAgreementId]);

  const onAgreementEndFlow = async (userDemographics?: Demographics) => {
    if (user) {
      if (userDemographics && !userDemographics.redirectRequired) {
        updateDate();
      } else {
        router.push('/demographics');
      }
    } else if (getLocalItem(CONTRIBUTION_SESSION_ITEM)) {
      if (userDemographics && !userDemographics.redirectRequired) {
        router.push('/confirm-email');
      } else {
        router.push('/demographics');
      }
    } else {
      router.push('/email');
    }
  };

  const handleAgreementClick = async () => {
    if (!agreeButtonInteractive) {
      return;
    }
    setAgreeButtonInteractive(false);
    try {
      const storedUserId = getLocalItem(CONTRIBUTION_SESSION_ITEM);
      const userId = getContributionUserId({
        user,
        storedUserId,
      });
      if (!agreeButtonInteractive) {
        const { blocked } = await getBlockedData({
          email: null,
          id: userId,
          endpointOnGql: project.features.userEndpointsOnGql,
          apiToken,
        });
        if (blocked) {
          dispatch({
            type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
            isOpen: true,
          });
        }
        return;
      }
      setAgreeButtonInteractive(false);
      const userDemographics = userId
        ? await fetchDemographicsByUserByProject(userId, project.id)
        : null;
      const status = getContributionStatus({
        user,
        storedUserId,
      });
      const agreement: Omit<Contribution<'agreement'>, '_id'> = {
        type: ContributionType.AGREEMENT,
        projectId: project._id,
        commentId,
        date: new Date().toISOString(),
        userId,
        demographicsId: userDemographics?._id || null,
        status,
        draft: false,
        surveySlug: contributionProposalSlug,
        pageId,
        language: router.locale,
        origin: 'commonplace',
      };
      const agreementForGaudi: ContributionGaudi<'agreement'> = {
        _id: '',
        type: ContributionType.AGREEMENT,
        project: project.id,
        comment_id: commentId,
        date: new Date(),
        user_id: userId,
        demographics_id: userDemographics?._id || null,
        status,
        language: router.locale,
        draft: false,
        origin: 'commonplace',
      };
      trackEvent(MixpanelEventTypes.AGREEING, {
        commentId,
        path: router.asPath,
      });
      if (commentSentiment && Number.isInteger(commentSentiment)) {
        agreementForGaudi.feeling = commentSentiment;
        agreement.feeling = commentSentiment;
      }
      if (user && userAgreementId) {
        // if logged in and clicking to agree with a comment I have already agreed
        // mark agreement as deleted
        // and do not continue to the whole end flow, just update the agreements counter
        await markAgreementDeletedBothDBs(
          userAgreementId,
          DeletedContributionReason.UNAGREE,
          apiToken
        );
        updateDate();
      } else {
        const savedAgreement = await saveAgreement(
          '/api/contributions',
          agreement
        );
        if (savedAgreement.gaming) {
          dispatch({
            type: PROPOSAL_ACTION_TYPES.SET_GAMING_BANNER_IS_OPEN,
            isOpen: true,
          });
          // This is because at this point no agreement was saved
          setAgreeButtonInteractive(true);
          return;
        }
        if (isMapComment) {
          mapDispatch({
            type: 'ADD_AGREEMENT',
            payload: { contributionId: savedAgreement._id, ...savedAgreement },
          });
        } else {
          dispatch({
            type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_ID,
            contributionId: savedAgreement._id,
          });
          dispatch({
            type: PROPOSAL_ACTION_TYPES.SET_CONTRIBUTION_TYPE,
            contributionType: ContributionType.AGREEMENT,
          });
        }
        await saveAgreement('/api/contributions?originDb=gaudi', {
          ...agreementForGaudi,
          _id: savedAgreement._id,
        });
        trackEvent(MixpanelEventTypes.AGREED, {
          contributionId: savedAgreement._id,
        });
        await checkOnUserLanguage({
          user,
          i18nLang: i18n.language,
          userEndpointsOnGql: project.features.userEndpointsOnGql,
          apiToken,
        });
        await onAgreementEndFlow(userDemographics);
      }
    } catch (error) {
      captureException(
        `Error on handleAgreementClick @ AgreeButton.tsx : ${error}`
      );
    }
  };

  return (
    <Button
      className={className}
      onClick={handleAgreementClick}
      data-testid="SocialButton-Agree"
    >
      {children}
    </Button>
  );
};
