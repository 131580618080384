import styled, { css } from 'styled-components';
import { meetsContrastGuidelines } from 'polished';
import {
  CardHeader as BaseCardHeader,
  CardMedia as BaseCardMedia,
  CardContent as BaseCardContent,
} from 'Atoms/Card/ProjectCard/ProjectCard.styles';
import { Button } from 'Client/components/molecules';

export const CardHeader = styled(BaseCardHeader)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey[300]}`};
`;

export const CardMedia = styled(BaseCardMedia)`
  height: 9rem;
`;

export const CardContent = styled(BaseCardContent)``;

export const CardV2Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5625rem;

  width: 100%;
  height: 4.0625rem;

  position: relative;
  &::before {
    content: '';
    height: 1px;
    width: calc(100% - 3.125rem);
    background-color: ${({ theme }) => theme.colors.grey['A400']};
    position: absolute;
    top: 0;
  }
`;

export const FooterLeftSide = styled.div`
  & > span {
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
  }
`;

export const FooterSummary = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    padding: 0 5px;
    &:hover {
      color: ${theme.colorMappings.brand};
      & > svg > path {
        fill: ${theme.colorMappings.brand};
      }
    }
  `}
`;

export const FooterRightSide = styled.div`
  padding: 0.8rem 0;
`;

export const ToolTipContent = styled.div`
  padding: 0.8rem 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ToolTipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  & > div {
    display: flex;
    flex-direction: column;
    & > span {
      font-weight: 700;
    }
    & > p {
      font-weight: 400;
    }
    & > span,
    p {
      line-height: 0.8rem;
      margin: 0;
      font-size: 1rem;
    }
  }
`;

export const CallToActionButton = styled(Button)`
  ${({ theme }) => {
    const { brand, black, white, primaryButtonBackground } =
      theme.colorMappings;
    const isAccessible = meetsContrastGuidelines(brand, black);

    return css`
      border-radius: 2.3125rem;
      border: none;
      background-color: ${primaryButtonBackground};
      color: ${isAccessible.AA ? black : white};
      font-weight: 700;
      font-size: 0.75rem; // 12px
      padding: 0.8125rem 1rem;
      min-height: 2.5rem;
      font-family: 'Roboto', sans-serif;

      line-height: 0.6875rem;

      &:hover {
        border: none;
        background-color: ${primaryButtonBackground};
        color: ${isAccessible.AA ? black : white};
      }

      @media (max-width: 950px) {
        padding: 0.5rem 0.75rem;
        min-height: 2rem;
        font-size: 0.625rem;
        line-height: 0.5rem;
      }
    `;
  }}
`;
