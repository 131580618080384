import { captureException } from '@sentry/node';
import fetch from 'isomorphic-unfetch';
import { CONSENT_TYPES } from 'Client/constants/consents';
import { EMAIL_TYPES } from 'Client/constants/emailTypes';
import { ProjectProps } from 'Shared/types';
import { Consent } from 'Shared/types/consent';
import { saveConsents } from '../consent';

interface ProjectNewsSubscriptionParams {
  email: string;
  lang?: string;
  project: ProjectProps;
  hasNews: boolean;
  isTestProject?: boolean;
  apiToken: string;
}

export const requestProjectNewsSubscription = async ({
  email,
  lang = 'en-GB',
  project,
  hasNews,
  isTestProject,
  apiToken,
}: ProjectNewsSubscriptionParams):
  | Promise<{
      ok: boolean;
      consents: Consent[];
    }>
  | never => {
  try {
    const consents = [];

    if (hasNews) {
      consents.push({
        type: CONSENT_TYPES.PROJECT_NEWS,
      });
    }

    if (isTestProject) {
      consents.push({
        type: CONSENT_TYPES.PROJECT_LAUNCH,
      });
    }

    const response = await saveConsents({
      email,
      lang,
      consents,
      project,
      apiToken,
    });

    if (!response.ok) {
      throw new Error('Could not save news subscription consent.');
    }

    await fetch(`/api/email/projectNewsSubscription`, {
      method: 'POST',
      body: JSON.stringify({
        project,
        email,
        lang,
        type: hasNews
          ? EMAIL_TYPES.NEWS_CONFIRMATION
          : EMAIL_TYPES.PROJECT_LAUNCH,
      }),
    });

    return response;
  } catch (error) {
    captureException(
      `Error in requestProjectNewsSubscription @ requestProjectNewsSubscription.ts : ${error}`
    );

    throw new Error();
  }
};
