import { captureException } from '@sentry/node';
import { User } from 'Shared/types';
import { SupportedLanguages } from 'Client/constants/languages';
import { updateUser } from './';

type Params = {
  user?: User | undefined;
  i18nLang: SupportedLanguages | string;
  userEndpointsOnGql: boolean;
  apiToken: string;
};

export const checkOnUserLanguage = async ({
  user,
  i18nLang,
  userEndpointsOnGql,
  apiToken,
}: Params): Promise<void> => {
  if (user && (!user.language || user.language !== i18nLang)) {
    try {
      await updateUser({
        userId: user._id,
        updates: { language: i18nLang as SupportedLanguages },
        userEndpointsOnGql,
        apiToken,
      });
    } catch (error) {
      captureException(
        `error in checkOnUserLanguage @ checkOnUserLanguage : ${error}`
      );
    }
  }
};
