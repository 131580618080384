import fetch from 'isomorphic-unfetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from 'crypto-hash';
import { setContext } from '@apollo/client/link/context';
import { createLink } from './apollo-link';
import getApiUrl from './getApiUrl';

const getClient = (apiToken: string) => {
  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      'X-Auth-Token': apiToken,
    },
  }));

  const isServer = typeof window === 'undefined';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const windowApolloState = !isServer && window?.__NEXT_DATA__?.apolloState;

  return new ApolloClient({
    ssrMode: isServer,
    link: authLink.concat(createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: true
    }).concat(createLink({ uri: getApiUrl(), fetch }))),
    uri: getApiUrl(),
    cache: new InMemoryCache().restore(windowApolloState || {}),
  });
};

export default getClient;
