import styled from 'styled-components';
import { opacify } from 'polished';
import { Button } from 'Client/components/molecules';
import { LabelBase } from '../../../Form/Label';

export const Wrapper = styled.div`
  padding: 0.75rem 0.625rem 3.25rem 0;
`;

export const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  & > input {
    margin: 0;
    height: 3rem;
  }
`;
export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
`;

export const OptionWrapperActive = styled(OptionWrapper)`
  border: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 0.25rem 0.25rem ${({ theme }) => theme.colorMappings.shadow};
  padding: 0 0.5rem;
`;

export const AddButton = styled(Button)`
  ${({ theme }) => `
  border: 1px solid ${theme.colorMappings.brand};
  color: ${theme.colorMappings.brand};
  border-radius: 0.3125rem;
  background: white;
  width: 100%;
  background: ${theme.colorMappings.editModeBlue};
  color: ${theme.colorMappings.white};

  &:hover{
    background: ${opacify(-0.8, theme.colorMappings.editModeBlue)};
    border: 1px solid ${theme.colorMappings.brand};
    color: ${theme.colorMappings.white};
  }
  & > span{
    & > p{
      font-size: 0.9375rem;
      font-style: normal;
      margin: 0;
    }
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
  }
  `}
`;

export const OptionsSection = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const OptionLabel = styled(LabelBase)`
  font-size: 1.125rem;
  font-weight: 700;
`;

export const AnswerLimit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SelectLimit = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  & > p {
    margin: 0;
    font-size: 1rem;
  }
`;

export const OptionItemValidations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  & > span {
    margin-top: 0;
  }
`;
