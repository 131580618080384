import { SaveConsentsProps } from 'Client/pages/preferences/types';
// import { saveConsent as saveConsentGql } from 'Client/services/consent/saveConsent.gql';
import { Consent } from 'Shared/types/consent';

const saveConsentsOld = async ({
  consents,
  email,
  lang,
}: SaveConsentsProps) => {
  return fetch('/api/consent', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      consents,
      email,
      ...(lang && { lang }),
    }),
  });
};

export const saveConsents = async ({
  consents,
  email,
  lang,
  project, // user, apiToken, pageId,
}: SaveConsentsProps): Promise<{ ok: boolean; consents: Consent[] }> => {
  // if (project.features.cf3) {
  //   const savedConsents = [];
  //   for await (const consent of savedConsents) {
  //     const savedConsent = await saveConsentGql(
  //       {
  //         ...consent,
  //         email,
  //         project: project.id,
  //         projectId: project._id,
  //         date: new Date(),
  //         ...(user ? { userId: user._id } : {}),
  //         ...(pageId ? { pageId } : {}),
  //       },
  //       apiToken
  //     );
  //     savedConsents.push(savedConsent);
  //   }
  //   return {
  //     ok: true,
  //     consents: savedConsents,
  //   };
  // }
  const data = saveConsentsOld({ consents, email, lang, project });
  console.log('🚀 ~ data:', data);
  return (await data).json();
};
