import * as React from 'react';
import { isEmpty } from 'Shared/utils/isEmpty';
import type { SyntaxValidationResult } from 'Shared/types/sendgrid';

/**
 * returns 'true' if string passed is a valid email of the format:
 * valid.local.string@valid.domain.string
 */
export const validateEmail = (email: string): boolean => {
  const re = /^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Z0-9-]+\.[A-Z0-9.-]+$/i;
  return re.test(email);
};

/**
 * returns false if array length equals 0
 */
export const validateEmptyArray = (array: string[]): boolean => {
  return array.length === 0 ? false : true;
};

/**
 * Return type for the syntax validation result parser.
 */
export type ValidationStatusData = {
  type: 'error' | 'warning';
  message: string | React.ReactElement;
};

/**
 * Analyse result of the sendgrid email syntax validation api call and return a
 * corresponding validation error or warning message if required else return a
 * null value.
 */
export const parseSyntaxValidationResult: (
  t: (key: string, options?: Record<string, unknown>) => string
) => (result: SyntaxValidationResult) => ValidationStatusData | null =
  (t) => (result) => {
    if (isEmpty(result)) return null;

    try {
      if (result?.verdict === 'Invalid') {
        return {
          type: 'error',
          message: t('This does not appear to be a valid email address'),
        };
      }

      if (result?.checks?.domain?.is_suspected_disposable_address) {
        return {
          type: 'error',
          message: t(
            'Please use a personal email address. Temporary email providers cannot be used.'
          ),
        };
      }

      if (!result?.checks?.domain?.has_mx_or_a_record) {
        return {
          type: 'error',
          message: t(
            "We've detected that your email domain is invalid. Get in touch with support@commonplace with any questions."
          ),
        };
      }

      if (result?.checks?.local_part?.is_suspected_role_address) {
        return {
          type: 'warning',
          message: t(
            'We suggest you use a personal email address. You’re more likely to receive a response to your contribution.'
          ),
        };
      }

      if (result?.checks?.additional?.has_known_bounces) {
        return {
          type: 'warning',
          message: t(
            "We've previously had difficulty delivering to this email address. Please double check it before submitting."
          ),
        };
      }

      if (result?.suggestion) {
        const { local, suggestion } = result;
        return {
          type: 'warning',
          message: t('Did you mean {{ email }}?', {
            email: `${local}@${suggestion}`,
          }),
        };
      }
    } catch {
      return null;
    }

    return null;
  };

export const isValidUrl = (url: string): boolean => {
  const urlRegex =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  return urlRegex.test(url);
};
