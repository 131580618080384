import { SupportedLanguages } from 'Client/constants/languages';
import { HeroContent } from 'Client/pages/hub';
import { ProposalStage } from 'Client/pages/proposals';
import { MultiLanguageContent, ProjectProps } from '.';

export enum PageTypes {
  PROPOSAL = 'proposal',
  PROPOSALS = 'proposals',
  TEAMS = 'teams',
  MILESTONES = 'milestones',
  HUB = 'hub',
  MAP = 'map',
  EMAIL = 'email',
  CONFIRM_EMAIL = 'confirm-email',
  FAQ = 'faq',
  DEMOGRAPHICS = 'demographics',
  PREFERENCES = 'preferences',
  PLANNING_APP = 'planningapp',
  TILES = 'tiles',
}

export interface DbPage {
  _id: string;
  projectId: string;
  userId: string;
  slug: string;
  stage?: ProposalStage | null;
  hero?: HeroContent;
  type: PageTypes;
  createdAt: Date | string; // ISO string dates are strings
  active: boolean;
  content: MultiLanguageContent<Array<string>>; // the content with the lang version ids
  contentVersionId?: string;
  order?: number;
}

export interface Page<T = Partial<Record<string, unknown>>> {
  _id: string;
  projectId: string;
  userId: string;
  slug: string;
  stage?: ProposalStage | null;
  hero?: HeroContent;
  type: PageTypes;
  createdAt: Date | string; // ISO string dates are strings
  active: boolean;
  content: T; // the actual content of the Page, not the lang version ids
  contentVersionId?: string;
  order?: number;
  scDocumentIds?: Record<SupportedLanguages, string>;
  featureFlags?: PageFeatures;
}
export interface PageFeatures {
  notCommentable?: boolean;
  hideWelcomePanel?: boolean;
  hideProjectInfoTab?: boolean;
  saveMapAnswersOnPostgres?: boolean;
  printPage?: boolean;
  showAddressSearchField?: boolean;
  hideMapLayersTab?: boolean;
  hideMapFiltersTab?: boolean;
  hideMapSentimentLegends?: boolean;
  alwaysShowDemographicsPage?: boolean;
}
export interface ProjectCardData extends ProjectProps {
  title: string;
  commentCount: number;
  agreeCount: number;
  image?: {
    src?: string;
    alt?: string;
  };
  description?: string;
}
