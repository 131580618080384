import * as React from 'react';
import { EDITABLE_CONTENT_BLOCK_TYPES } from 'Client/pages/edit/constants';
import { ActionTile, TextIndicator, TileProps } from '.';
import { TileDataType } from './types';

const PreventDeleteList = [
  EDITABLE_CONTENT_BLOCK_TYPES.HERO,
  EDITABLE_CONTENT_BLOCK_TYPES.NEWS,
  EDITABLE_CONTENT_BLOCK_TYPES.TEAM,
  EDITABLE_CONTENT_BLOCK_TYPES.NAV,
];
const PreventToggleList = [
  EDITABLE_CONTENT_BLOCK_TYPES.HERO,
  EDITABLE_CONTENT_BLOCK_TYPES.NAV,
];
const PreventDuplicateList = [EDITABLE_CONTENT_BLOCK_TYPES.MILESTONES];

export const HubTile: React.FC<Partial<TileProps>> = ({
  data,
  ...props
}: Partial<TileProps>) => {
  const showToggle = PreventToggleList.includes(
    data.type as EDITABLE_CONTENT_BLOCK_TYPES
  )
    ? false
    : true;
  const showMenu = PreventDeleteList.includes(
    data.type as EDITABLE_CONTENT_BLOCK_TYPES
  )
    ? false
    : true;
  const showDuplicate = PreventDuplicateList.includes(
    data.type as EDITABLE_CONTENT_BLOCK_TYPES
  )
    ? false
    : true;

  const getTileTitle = () => {
    const tilesTitleWithLabel: TileDataType[] = [
      EDITABLE_CONTENT_BLOCK_TYPES.DYNAMIC_PROPOSALS,
      EDITABLE_CONTENT_BLOCK_TYPES.PROGRAMME,
    ];
    if (tilesTitleWithLabel.includes(data.type)) {
      return `${data.title}: ${data.content?.['label'] || ''}`;
    }
    return data.title;
  };

  return (
    <ActionTile
      title={getTileTitle()}
      hasToggle={showToggle}
      hasMenu={showMenu}
      hasDuplicate={showDuplicate}
      subheaderContent={<TextIndicator />}
      data={data}
      locked={data.locked}
      index={props.index}
      {...props}
    />
  );
};
