import { CommentsWithQuestions } from 'Server/services/comments/types';
import { OpenGraphProps, Question } from 'Shared/types';
import { Contribution, ContributionType } from 'Shared/types/contribution';
import { Page, PageFeatures } from 'Shared/types/page';
import { LabelValueOptions, QuestionTypes } from 'Shared/types/question';
import { GLWorkspace } from 'Shared/types/proposals';
import { CardContent, ProposalSection, ProposalStage } from '../proposals';

export type MapPageProps = {
  pageId: string;
  slug: string;
  version?: string;
  stage?: ProposalStage | null;
  type?: string;
  createdAt: Date | string;
  geolytixWorkspace?: GLWorkspace;
  content?: {
    geolytixWorkspace?: GLWorkspace;
    questions?: Array<{ question: string; order?: number }>;
  };
  previewUrl?: string;
  sentimentQuestion: { question: string; order: number };
  questions: Array<{ question: string; order?: number }>;
  card: CardContent;
  infoPanel?: {
    title?: string;
    description?: string;
    image?: { src: string; alt: string };
    link?: {
      label: string;
      url: string;
    };
  };
  contributionsCount?: {
    comments: number;
    agreements: number;
  };
  openGraphInfo?: OpenGraphProps;
  filters?: ContributionFilter[];
  featureFlags?: PageFeatures;
  cf3Props?: any; // to-do
  steps?: Array<ProposalSection<string>>;
};

export interface MapPagePropsWithContent extends MapPageProps {
  active?: boolean;
  content?: MapPageProps;
  contentVersionId?: string;
  created_at?: Date | string;
  projectId: string;
  showMinutesLeft?: boolean;
  userId?: string;
  __v?: number;
  _id?: string;
}

export enum MapTables {
  CONTRIBUTIONS = 'contributions',
  CUSTOM_LAYER_4258 = 'custom_layer_4258',
  CUSTOM_LAYERS = 'custom_layers',
  IMAGE_3D = 'image_3d',
  PLANNING_APPS = 'planning_apps',
}

/*
 *   TO-DO: implement PostgresColumns using typed interface
 *   e.g. type MapTables.CONTRIBUTIONS && columns = 'id', 'geom', 'slug', 'contribution_id', 'sentiment', 'project', 'draft', 'user_id', 'status', 'is_deleted', 'pivot', 'page_id', 'metadata
 *
 */

interface ContributionFilterOptions {
  label: string;
  value: string;
  selectedColour: string; // Hex or rgb
  icon: string; // SVG
}

interface ContributionFilter {
  order: number;
  queryOrder?: number;
  label: string;
  postgresTable: MapTables;
  postgresColumn: string;
  type: QuestionTypes & FILTERS_VIEW_COMPONENTS_TYPE;
  questionId?: string; // the question you'll search for
  parentQuestionId?: string; // the question it will fallback to '- All' options
  content?: string; // textContent
  title?: string; // textContent
  language?: string;
  quickFilter?: true;
  multiSelect?: true;
  placeholder?: string;
  tabs?: FilterTabs[];
  options?: Array<ContributionFilterOptions>;
  searchType?: string;
  variant?: FILTERS_VIEW_MULTISELECT_VARIANTS;
}

export enum FILTERS_VIEW_COMPONENTS_TYPE {
  TEXTPOLL = 'textpoll',
  MULTISELECT = 'multiselect',
  TEXT_CONTENT = 'textContent',
}

export enum FILTERS_VIEW_MULTISELECT_VARIANTS {
  EXPANDABLE = 'expandable',
  INLINE = 'inline',
  CHECKBOX = 'checkbox',
}

export type FilterOptions = {
  label: string;
  placeholder?: string;
  id?: string;
  name: string;
  options: LabelValueOptions[] | Question['options'];
  isSimilarFilter?: boolean;
  questionId?: string;
  parentQuestionId?: string;
  type?: FILTERS_VIEW_COMPONENTS_TYPE;
  order?: number;
};

export type TextContentSection = {
  type: FILTERS_VIEW_COMPONENTS_TYPE.TEXT_CONTENT;
  title?: string;
  content: string;
  order: number;
};

interface FilterTabs {
  label: string;
  value: string;
  options: ContributionFilterOptions[];
}

export interface MapContentWithSteps extends Omit<MapPageProps, 'steps'> {
  steps: Array<ProposalSection<Question>>;
}

export type MapPageWithContent = Page<MapPageProps>;

export type MapPageWithQuestions = Page<MapContentWithSteps>;

export type MapContributionsProps = {
  proposalTitle: string;
  totalContributions: number;
  contributions: Array<CommentsWithQuestions>;
  groupedSentiments: { [sentiment: string]: number };
  proposalSlug: string;
  userAgreements: Array<Contribution<ContributionType.AGREEMENT>>;
};

// export interface MapPageContent {
//   geolytixWorkspace?: GLWorkspace;
//   sentimentQuestion: { question: string; order: number };
//   questions: Array<{ question: string; order?: number }>;
//   card: CardContent;
//   previewUrl?: string;
// }
